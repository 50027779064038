import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_URL + "/";
let http;

class OrgReviewersService {
  constructor() {
    http = axios.create({
      baseURL: API_URL,
      headers: authHeader()
    });
    http.interceptors.request.use(
      config => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user.token) {
          config.headers.Authorization = "Bearer " + user.token;
        }
        return config;
      },
      error => Promise.reject(error)
    );
  }

  get({ query }) {
    return http.get(`my_org_reviewers`, {
      params: query,
      headers: authHeader()
    });
  }

  getReviewOrgs({ query }) {
    return http.get(`my_reviewable_orgs`, {
      params: query,
      headers: authHeader()
    });
  }

  create({ uid }) {
    return http.post(`authorize_reviewer/${uid}`, {});
  }

  createReportReview({ data }) {
    console.log("data2", data);
    return http.post(`report_review`, data, {
      headers: authHeader()
    });
  }

  remove({ uid }) {
    return http.delete(`deauthorize_reviewer/${uid}`);
  }
}

export default new OrgReviewersService();
