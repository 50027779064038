<template>
  <div class="dashboard-org">
    <Header></Header>
    <v-container v-if="!dataLoading" class="my-3">
      <v-row>
        <v-col md="12">
          <h1 class="text-h6 mr-3">
            {{ quiz.name }}
          </h1>
          <h2 class="mt-5">
            {{ quiz.report_template.name }}
          </h2>
          <div id="links" class="mt-3"></div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div v-html="reportTmplHtml" class="report-html"></div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card max-width="100%">
            <v-list subheader three-line>
              <v-subheader>Reviews</v-subheader>
              <v-list-item v-for="item in reviews" :key="item.id">
                <v-list-item-content>
                  <v-list-item-title>{{ item.status }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.comments }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-form v-model="valid" ref="reviewform" lazy-validation>
            <v-select
              v-model="status"
              :items="statusList"
              :rules="statusRules"
              filled
              label="Status"
              required
            ></v-select>
            <v-textarea
              label="Comment"
              :rules="commentRules"
              v-model="comment"
              filled
              required
            ></v-textarea>
            <v-btn
              :disabled="!valid"
              rounded
              color="primary"
              class="mr-4"
              @click="addReview()"
            >
              Add Review
            </v-btn>
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <v-snackbar v-model="snackbar" right top>
            {{ alert }}
            <template v-slot:action="{ attrs }">
              <v-btn color="white" text v-bind="attrs" @click="snackbar = false"
                >Close</v-btn
              >
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
    </v-container>

    <template v-else>
      <!-- loading -->
      <v-col cols="12">
        <p class="text-center text-overline mb-8">Loading new data</p>
        <v-progress-linear
          color="secondary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </template>
  </div>
</template>
<script>
// @ is an alias to /src
import axios from "axios";
import Header from "@/components/Header";
import CoursesService from "@/services/courses.service";
import ReportService from "@/services/report.service";
import ReviewersService from "@/services/reviewers.service";
import moment from "moment";
import { Role } from "@/helpers/roles";
import showdown from "showdown";

export default {
  name: "quizreport",
  components: {
    Header
  },
  data() {
    return {
      quiz: {},
      valid: false,
      quizzes: [],
      reviews: [],
      statusList: ["Approved", "Rejected", "Pending"],
      reviewer_id: null,
      status: "",
      comment: "",
      commentRules: [v => !!v || "Comment is required"],
      statusRules: [v => !!v || "Status is required"],
      reportTmplHtml: "",
      reportTemplate: {},
      questions: {},
      dataLoading: false,
      snackbar: false,
      alert: "",
      markdown: "# hello",
      htmlData: "",
      tocHtml: "",
      table:
        "| h1    |    h2   |      h3 |\n |:------|:-------:|--------:|\n | 100   | [a][1]  | ![b][2] |\n | *foo* | **bar** | ~~baz~~ |\n",
      htmlHeader:
        '<!DOCTYPE html> <html lang="en"> <head> <meta charset="UTF-8"> <meta http-equiv="X-UA-Compatible" content="IE=edge"> <meta name="viewport" content="width=device-width, initial-scale=1.0"> <title>Document</title> </head> <body>',
      htmlFooter: "</body></html>",
      reportJson: "",

      tmplVars: {
        courseTitle: "",
        organizationCompany: "",
        workergroupName: "",
        locationAddress: "",
        reportCreatedAt: "",
        organizationTeamMembers: "",
        organizationLogo: ""
      },

      tmplPlaceholders: [
        "{{courseTitle}}",
        "{{organizationCompany}}",
        "{{workergroupName}}",
        "{{locationAddress}}",
        "{{reportCreatedAt}}",
        "{{organizationTeamMembers}}",
        "{{organizationLogo}}"
      ],

      report_template_custom_blocks: []
    };
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },

    isAdminEditor() {
      let adminEditor = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.AdminEditor) {
          adminEditor = true;
        }
      });
      return adminEditor;
    },

    catId() {
      return this.$route.query.cat_id;
    },

    quizId() {
      return this.$route.params.id;
    }
  },

  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
    this.initialize();
  },

  methods: {
    convert(markdownTxt) {
      let converter = new showdown.Converter();
      this.htmlData = converter.makeHtml(markdownTxt);

      return this.htmlData;
    },
    showSnackbar(text) {
      this.alert = text;
      this.snackbar = true;
    },
    async initialize() {
      this.dataLoading = true;

      //get the report
      const response = await ReportService.getExtendedReport({
        id: this.$route.params.id
      });

      this.extendedReport = response.data;
      console.log("extended report", this.extendedReport);

      this.reviewer_id = this.extendedReport.reviewer.id;
      this.reviews = this.extendedReport.reviews;

      let quizIds = Object.keys(this.extendedReport.quiz);
      let quizValues = Object.values(this.extendedReport.quiz);

      let firstQuizId = quizIds[0];
      for (let i = 0; i < quizIds.length; i++) {
        this.quizzes[quizIds[i]] = quizValues[i];
      }

      let quizId = this.extendedReport.quiz[firstQuizId].info.id;

      const quizData = await CoursesService.getQuiz(quizId);
      this.quiz = quizData.data.data;
      this.reportTemplate = this.quiz.report_template;

      // get the first quiz custom blocks
      if (this.quiz.report_template_custom_blocks.length > 0)
        this.report_template_custom_blocks = this.quiz.report_template_custom_blocks;

      //get the second quiz custom blocks
      const quizData2 = await CoursesService.getQuiz(quizIds[1]);
      const quiz2 = quizData2.data.data;
      if (quiz2.report_template_custom_blocks.length > 0) {
        this.report_template_custom_blocks = this.report_template_custom_blocks.concat(
          quiz2.report_template_custom_blocks
        );
      }

      this.setReportTmplMarkdown();

      this.generateToc();
      this.generatePDF();
      this.dataLoading = false;
    },

    setReportTmplMarkdown() {
      let markdownHtml = "";

      this.reportTemplate.groups.forEach(group => {
        markdownHtml += `<div class="block">`;
        group.blocks.forEach(block => {
          let blockHtml = this.convert(block.content);
          let blockId = block.name.replace(/\s/g, "");
          markdownHtml += `
          <h2 id="${blockId}">${block.name}</h2>
              ${blockHtml}
          `;
        });
      });
      markdownHtml += `</div>`;

      this.reportTmplHtml = markdownHtml;

      // add custom blocks
      this.addCustomBlocks();
    },

    async generatePDF() {
      const session_url = "https://app.useanvil.com/api/v1/generate-pdf";
      const YOUR_API_KEY = "NFIcGGyT1ytLpLAQHpJIjwUJ3FY1ZeQy";

      const encodedToken =
        "Basic " + Buffer.from(YOUR_API_KEY, "ascii").toString("base64");

      this.tmplVars.courseTitle = "";
      this.tmplVars.organizationCompany = this.quiz.organization.company;
      this.tmplVars.workergroupName = this.extendedReport.workergroup.name;
      //this.tmplVars.organizationTeamMembers = this.quiz.organization.team_members;
      //this.tmplVars.organizationLogo = this.quiz.organization.logo;

      //replace variable placeholders
      let indx = 0;
      if (this.reportTmplHtml !== "") {
        for (const item in this.tmplVars) {
          if (this.tmplVars[item] && this.tmplPlaceholders[indx]) {
            this.reportTmplHtml = this.reportTmplHtml.replaceAll(
              this.tmplPlaceholders[indx],
              this.tmplVars[item]
            );
          }

          indx++;
        }
        //parse the shortcodes
        this.reportTmplHtml = this.parseReplaceShortcodes();

        //generate PDF File
        const data = {
          title: "PDF Template Sample",
          data: {
            html: this.htmlHeader + this.reportTmplHtml + this.htmlFooter,

            css:
              'html, body { font-family: Roboto, sans-serif; line-height: 1.5rem; } table {width: 100%; max-width: 100%; margin-bottom: 1rem; background-color: transparent; margin-bottom: 1.5rem; margin-top: 1.5rem;} th { background-color: #ccc !important; } td, th { padding: .75rem; vertical-align: top; border-bottom: 1px solid #dee2e6; } #nohead { color: #fff; background-color: #fff !important; height: 5px; border-bottom: 3px solid #2f91ae !important; } h1, h2, h3, h4, h5, h6 { font-family: Montserrat, sans-serif; color: #000000; color: #2f91ae; margin-bottom: .5rem} a {color: #2f91ae;} .block ul {list-style-type: circle;} .block li { list-style: none; font-size: 1rem; } .block li:before { content:"·"; font-size:2.5rem; margin-right:5px; vertical-align:middle; line-height:1rem; } .custom-blocks {margin-top: 30px;}'
          },
          page: {
            width: "805px",
            height: "1102px",
            margin: "50px",
            marginTop: "30px",
            marginBottom: "30px",
            marginLeft: "60px",
            marginRight: "60px",
            pageCount: "bottomRight"
          },
          fontSize: 12
        };

        try {
          let res = await axios.post(session_url, data, {
            responseType: "arraybuffer",
            headers: { Authorization: encodedToken }
          });

          let result = res.data;

          const file = new Blob([result], { type: "application/pdf" });

          file.name = "new name.pdf";

          const fileURL = URL.createObjectURL(file);

          const downloadUrl = document.createElement("a");
          downloadUrl.href = fileURL;
          downloadUrl.download = "PDF Template Sample.pdf";
          downloadUrl.innerHTML = "Download PDF";

          const viewUrl = document.createElement("a");
          viewUrl.href = fileURL;
          viewUrl.setAttribute("target", "_blank");
          viewUrl.innerHTML = "View PDF";

          const lineBr = document.createElement("br");

          const linksContent = document.createElement("div");
          linksContent.classList.add("file-links");

          const linksContainer = document.querySelector("#links");
          linksContent.appendChild(downloadUrl);
          linksContent.appendChild(lineBr);
          linksContent.appendChild(viewUrl);
          linksContainer.innerHTML = "";
          linksContainer.append(linksContent);
        } catch (error) {
          console.log(error.response);
        }
      }
    },

    /**
     * [quiz id="{id}" question_id="{id}"]
     * [quiz id="{id}" prop="${attrs.prop}"]
     **/

    parseReplaceShortcodes() {
      let output;
      let htmContent = this.reportTmplHtml;
      try {
        output = this.parseShortCode(htmContent);
      } catch (err) {
        output = err;
      }

      //loop through all shortcodes
      output.forEach(shortcode => {
        //quiz
        const shortcodeName = shortcode.name;

        //id, question_id, prop
        const attrs = shortcode["attributes"];

        let replacement_text = "";
        let shortcode_str = "";

        switch (shortcodeName) {
          case "quiz":
            if (attrs.id) {
              const id = attrs.id;

              if (attrs.question_id) {
                const question_id = attrs.question_id;
                shortcode_str = `[quiz id="${id}" question_id="${question_id}"]`;
                if (this.quizzes[id]) {
                  let quizQuestions = this.quizzes[id].question;

                  const question = quizQuestions[question_id];
                  //if question exists in the quiz
                  if (question) {
                    const allAnswers = question.answers; //question.answers;
                    //single type answer
                    if (allAnswers.length <= 1) {
                      replacement_text = question.answer; //question.answer;
                      //multiple choice answer
                    } else {
                      replacement_text = `<ul>`;
                      allAnswers.forEach(answer => {
                        replacement_text += `<li>${answer.content}</li>`;
                      });
                      replacement_text += `</ul>`;
                    }
                  }
                } else {
                  console.log("error: no such id");
                }
              } else if (attrs.prop) {
                if (this.reportJson.quiz[id][attrs.prop]) {
                  shortcode_str = `[quiz id="${id}" prop="${attrs.prop}"]`;
                  replacement_text = this.reportJson.quiz[id][attrs.prop];
                }
              }
            }
            break;
          default:
            break;
        }
        htmContent = htmContent.replace(shortcode_str, replacement_text);
      });
      return htmContent;
    },

    parseShortCode(shortCode) {
      var re = /(\s+|\W)|(\w+)/g;
      var match;
      var token;
      var curAttribute = "";
      var quoteChar;
      var mode = "NOT STARTED";
      var shortcodesRes = [];
      var parsedValue = {
        name: "",
        attributes: {}
      };

      while ((match = re.exec(shortCode)) != null) {
        token = match[0];

        switch (mode) {
          case "NOT STARTED":
            if (token == "[") {
              mode = "GETNAME";
            }
            break;
          case "GETNAME":
            if (!/\s/.test(token)) {
              parsedValue.name += token;
            } else if (parsedValue.name) {
              mode = "PARSING";
            }
            break;
          case "PARSING":
            // if non text char throw it
            if (token == "]") {
              shortcodesRes.push(parsedValue);
              parsedValue = {
                name: "",
                attributes: {}
              };
              mode = "NOT STARTED";
            } else if (token == "=") {
              if (!curAttribute)
                throw 'invalid token1: "' +
                  token +
                  '" encountered at ' +
                  match.index;
              else mode = "GET ATTRIBUTE VALUE";
            } else if (!/\s/.test(token)) {
              curAttribute += token;
            } else if (curAttribute) {
              mode = "SET ATTRIBUTE";
            }
            break;
          case "SET ATTRIBUTE":
            // these are always from match[1]
            if (/\s/.test(token)) {
              parsedValue.attributes[curAttribute] = null;
            } else if (token == "=") {
              mode = "GET ATTRIBUTE VALUE";
            } else {
              throw 'invalid token2: "' +
                token +
                '" encountered at ' +
                match.index;
            }
            break;
          case "GET ATTRIBUTE VALUE":
            if (!/\s/.test(token)) {
              if (/["']/.test(token)) {
                quoteChar = token;
                parsedValue.attributes[curAttribute] = "";
                mode = "GET QUOTED ATTRIBUTE VALUE";
              } else {
                parsedValue.attributes[curAttribute] = token;
                curAttribute = "";
                mode = "PARSING";
              }
            }
            break;
          case "GET QUOTED ATTRIBUTE VALUE":
            if (/\\/.test(token)) {
              mode = "ESCAPE VALUE";
            } else if (token == quoteChar) {
              mode = "PARSING";
              curAttribute = "";
            } else {
              parsedValue.attributes[curAttribute] += token;
            }
            break;
          case "ESCAPE VALUE":
            if (/\\'"/.test(token)) {
              parsedValue.attributes[curAttribute] += token;
            } else {
              parsedValue.attributes[curAttribute] += "\\" + token;
            }
            mode = "GET QUOTED ATTRIBUTE VALUE";
            break;
        }
      }
      if (curAttribute && !parsedValue.attributes[curAttribute]) {
        parsedValue.attributes[curAttribute] = "";
      }
      return shortcodesRes;
    },

    addCustomBlocks() {
      let htmlBlocks = "<div class='custom-blocks'>";
      this.report_template_custom_blocks.forEach(block => {
        htmlBlocks += "<h3>" + this.convert(block.name) + "</h3>";
        htmlBlocks += "<p>" + this.convert(block.content) + "</p>";
      });
      htmlBlocks += "</div>";
      this.reportTmplHtml += htmlBlocks;
    },

    generateToc() {
      var level = 0;
      let toc = "";

      this.reportTmplHtml = this.reportTmplHtml.replace(
        /<h([\d]) id="([a-z0-9-]+)">([^<]+)<\/h([\d])>/gi,
        function(str, openLevel, anchor, titleText, closeLevel) {
          if (openLevel != closeLevel) {
            return str + "---";
          }

          if (openLevel > level) {
            //toc += new Array(openLevel - level + 1).join("../<ol>");
            toc += "<ol>";
          } else if (openLevel < level) {
            toc += new Array(level - openLevel + 1).join("</li></ol>");
          }

          level = parseInt(openLevel);

          //var anchor = titleText.replace(/ /g, "_");
          toc += '<li><a href="#' + anchor + '">' + titleText + "</a>";

          return (
            "<h" +
            openLevel +
            '><a name="' +
            anchor +
            '">' +
            titleText +
            "</a></h" +
            closeLevel +
            ">"
          );
        }
      );

      if (level) {
        toc += new Array(level + 1).join("</ol>");
      }

      this.reportTmplHtml =
        "<div id='contentsList'><h3>Table of Contents</h3>" +
        toc +
        "</div>" +
        this.reportTmplHtml;
    },

    async addReview() {
      const isValid = this.$refs.reviewform.validate();
      if (isValid) {

        let data = {
          report_id: this.$route.params.id,
          reviewer_id: this.reviewer_id,
          status: this.status,
          comments: this.comment
        };
        const response = await ReviewersService.createReportReview({ data });

        this.reviews.push(response.data.data);
        this.showSnackbar("The Review has been added successfully");
        //this.reportId = response.data.report_id;

      }
      this.$refs.reviewform.reset();
    },

    getOrgId() {
      const id = null;

      return this.currentUser.user.organizations &&
        this.currentUser.user.organizations.id
        ? this.currentUser.user.organizations.id
        : id;
    },

    formatQuizDate() {
      this.quiz.updated_at = moment(this.quiz.updated_at).format(
        "YYYY-MM-DD h:mm a"
      );
    }
  }
};
</script>
